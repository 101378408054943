<template>
    <div>
        <div class="h-handle-bg">
            <div class="h-handle-button">
                <p class="h-return"><el-button type="text" size="small" @click="$parent.checkSaveStatus">返回</el-button></p>
            </div>
        </div>
        <el-tabs class="done" type="border-card">
            <el-tab-pane label="基本信息">
                <el-form ref="soForm" :model="dataSource">
                    <el-form-item>
                        <el-col :span="2" class="form-title">对账单号：</el-col>
                        <el-col :span="5">
                            <el-form-item>
                                <span>{{dataSource.CompareNo}}</span>
                            </el-form-item>
                        </el-col>
                        <el-col :span="2" class="form-title">仓库编码：</el-col>
                        <el-col :span="5">
                            <el-form-item>
                                <span>{{dataSource.WarehouseNo}}</span>
                            </el-form-item>
                        </el-col>
                        <el-col :span="2" class="form-title">仓库名称：</el-col>
                        <el-col :span="5">
                            <el-form-item>
                                <span>{{dataSource.WarehouseName}}</span>
                            </el-form-item>
                        </el-col>
                    </el-form-item>
                    <el-form-item>
                        <el-col :span="2" class="form-title">库存类型：</el-col>
                        <el-col :span="5">
                            <el-form-item>
                                <span>{{dataSource.DisplayInventoryTypes}}</span>
                            </el-form-item>
                        </el-col>
                        <el-col :span="2" class="form-title">对账商品：</el-col>
                        <el-col :span="5">
                            <el-form-item>
                                <span>{{dataSource.DisplayIsAllProduct}}</span>
                            </el-form-item>
                        </el-col>
                        <el-col :span="2" class="form-title">开始时间：</el-col>
                        <el-col :span="5">
                            <el-form-item>
                                <span>{{dataSource.StartDate}}</span>
                            </el-form-item>
                        </el-col>
                    </el-form-item>
                    <el-form-item>
                        <el-col :span="2" class="form-title">状态：</el-col>
                        <el-col :span="5">
                            <el-form-item>
                                <span>{{dataSource.DisplayStatus}}</span>
                            </el-form-item>
                        </el-col>
                        <el-col :span="2" class="form-title">对账完成时间：</el-col>
                        <el-col :span="5">
                            <el-form-item>
                                <span>{{dataSource.CompleteDate}}</span>
                            </el-form-item>
                        </el-col>
                    </el-form-item>
                </el-form>
            </el-tab-pane>
        </el-tabs>
        <el-tabs class="done" type="border-card">
            <el-tab-pane label="对账差异">
                <!--<el-row class="h-table-choose">
                    <el-button i type="text" @click="showlist(0)" v-bind:class="0==queryParamCompare.params.status? 'activebtn':''">全部（{{SearchCountData.TotalCount}}）</el-button>
                    <el-button type="text" @click="showlist(1)" v-bind:class="1==queryParamCompare.params.status? 'activebtn':''">待处理（{{SearchCountData.ToDoCount}}）</el-button>
                    <el-button type="text" @click="showlist(2)" v-bind:class="2==queryParamCompare.params.status? 'activebtn':''">已处理调整（{{SearchCountData.AdjustCount}}）</el-button>
                    <el-button type="text" @click="showlist(3)" v-bind:class="3==queryParamCompare.params.status? 'activebtn':''">已处理不调整（{{SearchCountData.NoAdjustCount}}）</el-button>
                </el-row>-->
                <el-table :data="dataSource.StockCompareItemList.Result" border highlight-current-row>
                    <el-table-column prop="DisplayProductDate" label="商品编码">
                        <template slot-scope="scope">
                            <span v-model="scope.row.ProductCode">{{scope.row.ProductCode}}</span>
                        </template>
                    </el-table-column>
                    <el-table-column prop="ProductBatchNo" label="商品名称">
                        <template slot-scope="scope">
                            <span v-model="scope.row.ProductName">{{scope.row.ProductName}}</span>
                        </template>
                    </el-table-column>
                    <el-table-column prop="ProductSN" label="外部商品编码">
                        <template slot-scope="scope">
                            <span v-model="scope.row.OuterProductCode">{{scope.row.OuterProductCode}}</span>
                        </template>
                    </el-table-column>
                    <el-table-column prop="InventoryType" label="库存类型">
                        <template slot-scope="scope">
                            <span v-model="scope.row.DisplayInventoryType">{{scope.row.DisplayInventoryType}}</span>
                        </template>
                    </el-table-column>
                    <el-table-column prop="BatchNo" label="批次">
                        <template slot-scope="scope">
                            <span v-model="scope.row.BatchNo">{{scope.row.BatchNo}}</span>
                        </template>
                    </el-table-column>
                    <el-table-column prop="ProductBatchNo" label="生产批次">
                        <template slot-scope="scope">
                            <span v-model="scope.row.ProductBatchNo">{{scope.row.ProductBatchNo}}</span>
                        </template>
                    </el-table-column>
                    <el-table-column prop="ProductDate" label="生产日期">
                        <template slot-scope="scope">
                            <span v-model="scope.row.ProductDate">{{scope.row.ProductDate}}</span>
                        </template>
                    </el-table-column>
                    <el-table-column prop="ExpireDate" label="失效日期">
                        <template slot-scope="scope">
                            <span v-model="scope.row.ExpireDate">{{scope.row.ExpireDate}}</span>
                        </template>
                    </el-table-column>
                    <el-table-column prop="LotAttr4" label="OMS数量">
                        <template slot-scope="scope">
                            <span v-model="scope.row.OMSTotalNumber">{{scope.row.OMSTotalNumber}}</span>
                        </template>
                    </el-table-column>
                    <el-table-column prop="LotAttr4" label="OMS未冻结数量">
                        <template slot-scope="scope">
                            <span v-model="scope.row.OMSNormalNumber">{{scope.row.OMSNormalNumber}}</span>
                        </template>
                    </el-table-column>
                    <el-table-column prop="LotAttr4" label="OMS冻结数量">
                        <template slot-scope="scope">
                            <span v-model="scope.row.OMSFreezeNumber">{{scope.row.OMSFreezeNumber}}</span>
                        </template>
                    </el-table-column>
                    <el-table-column prop="LotAttr5" label="WMS数量">
                        <template slot-scope="scope">
                            <span v-model="scope.row.WMSTotalNumber">{{scope.row.WMSTotalNumber}}</span>
                        </template>
                    </el-table-column>
                    <el-table-column prop="LotAttr4" label="WMS未冻结数量">
                        <template slot-scope="scope">
                            <span v-model="scope.row.WMSNormalNumber">{{scope.row.WMSNormalNumber}}</span>
                        </template>
                    </el-table-column>
                    <el-table-column prop="LotAttr4" label="WMS冻结数量">
                        <template slot-scope="scope">
                            <span v-model="scope.row.WMSFreezeNumber">{{scope.row.WMSFreezeNumber}}</span>
                        </template>
                    </el-table-column>
                    <el-table-column prop="DisplayNormalNum" label="处理状态">
                        <template slot-scope="scope">
                            <span v-model="scope.row.DisplayHandleStatus">{{scope.row.DisplayHandleStatus}}</span>
                        </template>
                    </el-table-column>
                </el-table>
                <div class="h-table-list">
                    <div class="page">
                        <el-pagination @size-change="onSizeChangeCompare"
                                        @current-change="onPageChangeCompare"
                                        :current-page="queryParamCompare.pageIndex"
                                        :page-sizes="pageSizeArrayCompare"
                                        :page-size="queryParamCompare.pageSize"
                                        layout="total, sizes, prev, pager, next, jumper"
                                        :total="dataSource.StockCompareItemList.TotalCount">
                        </el-pagination>
                        <el-button type="text" class="btn-refurbish" v-on:click="onRefreshCompare">刷新</el-button>
                    </div>
                </div>
            </el-tab-pane>
        </el-tabs>
    </div>
</template>
<script>
    export default {
        data() {
            return {
                multipleSelection: [],
                queryParamCompare: {
                    pageIndex: 1,
                    pageSize: 10,
                    params: {
                        status: 0  //默认代表全部
                    }
                },
                SearchCountData: {
                    TotalCount: 0,
                    ToDoCount: 0,
                    AdjustCount: 0,
                    UpperWarnCount: 0
                }
            }
        },
        props: {
            config: {
                isDetailDisplay: false
            },
            pageSizeArrayCompare: {
                type: Array,
                default: function () {
                    return [10, 20, 30, 50, 100];
                }
            },
            dataSource: {
            }
        },
        mounted() {
            this.showlist();
            this.Event.$on("clearStockCompareForm", () => this.resetForm);
            this.Event.$on("showlist", () => this.showlist);
        },
        watch: {
            dataSource: {
                handler: function (curVal, oldVal) {
                    this.isDisabled = true;
                },
                deep: true
            }
        },
        methods: {
            onEditPageChange(param) {
                this.loadDateCompare();
            },
            onEditDataSourceChange(ds) {
                var _this = this;

                _this.dataSource = {
                    ColDefs: {
                        BodyFieldParams: []
                    },
                    Result: [],
                    TotalCount: 0
                };
                _this.$nextTick(function () {
                    _this.dataSource = ds;

                });
            },
            resetForm() {
                !this.isPassValidate && this.$refs['soForm'].resetFields();//清空表单
            },
            showlist: function (index) {
                this.queryParamCompare.params.status = index;
                this.loadDateCompare();
            },
            onRefreshCompare() {
                this.loadDateCompare();
            },
            onSizeChangeCompare(val) {
                this.queryParamCompare.pageSize = val;
                this.loadDateCompare();
            },
            onPageChangeCompare(val) {
                this.queryParamCompare.pageIndex = val;
                this.loadDateCompare();
            },
            loadDateCompare() {
                var _this = this;
                this.$ajax.query("omsapi/stockcompareitem/getnumberbywarehouseno", "post", this.queryParamCompare, (data) => {
                    _this.SearchCountData = data.Result;
                });
                this.queryParamCompare.params.ReconciliationNo = { fieldName: 'ReconciliationNo', fieldValue: this.dataSource.CompareNo };
                this.$ajax.query("omsapi/stockcompareitem/getlist", "post", this.queryParamCompare, (data) => {
                    _this.dataSource.StockCompareItemList.Result = data.Result;
                });
                
            }
        }
    }
</script>

<style>
</style>